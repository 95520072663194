<template>
  <div class="page">
    <div class="topBox">
      <div class="box-top" @click="show = true">
        <div class="txt">{{ placeStr }}</div>
        <img src="./img/arrow.png" :class="[{ downArrow: show }]" />
      </div>
      <div class="box-center" v-if="houseDetail.loadBearingWallUrl != ''">
        <iframe
          v-if="dialogShow"
          :src="houseDetail.loadBearingWallUrl"
          frameborder="0"
          name="myFrameName"
          id="myFrameId"
        >
        </iframe>
      </div>
      <div class="box-bottom">
        <div class="title">房屋基本户型</div>
        <p>
          户型：<span>{{ houseDetail.houseType || "-" }}</span>
        </p>
        <p>
          朝向：<span>{{ houseDetail.direction || "-" }}</span>
        </p>
        <p>
          建筑面积：<span>{{ houseDetail.size || "-" }}m</span>
        </p>
        <p>
          层高：<span>{{ houseDetail.storeyHeight || "-" }}m</span>
        </p>
        <p>
          交付时间：<span>{{ houseDetail.deliveryTime || "-" }}</span>
        </p>
      </div>
    </div>
    <div class="titleBox">装修注意事项</div>
    <div class="icon-list">
      <div
        class="item"
        @click="toDetail(item)"
        v-for="(item, index) in iconList"
        :key="index"
      >
        <div class="img-box">
          <img :src="item.iconUrl" alt="" />
        </div>
        <div class="name">{{ item.type }}</div>
      </div>
    </div>
    <v-picker
      :columns="authedSpaceList"
      :valueShow="show"
      :valueKey="valueKey"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-picker>
  </div>
</template>

<script>
import {
  getHouseInfoUrl,
  getMiniTipsListUrl,
  getAuthedSpaceInfoUrl,
} from "./api.js";
export default {
  name: "decorationTips",
  data() {
    return {
      dialogShow: true,
      show: false,
      iconList: [],
      authedSpaceList: [],
      valueKey: "label",
      placeStr: "",
      houseDetail: {
        loadBearingWallUrl: null,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  async created() {
    await this.getIconList();
  },
  mounted() {
    this.getAuthedSpaceList();
  },
  methods: {
    loadIframe() {
      this.dialogShow = false;
      this.$nextTick(function () {
        this.dialogShow = true;
      });
    },
    // 获取已认证的房屋信息list
    getAuthedSpaceList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${getAuthedSpaceInfoUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              item.label = item.spaceName;
              item.value = item.relatedSpaceId;
            });
            this.authedSpaceList = res.data;
            this.placeStr = this.authedSpaceList[0].label;
            this.loadIframe();
            this.getHouseDetail(this.authedSpaceList[0].value);
          }
        });
    },
    // 获取装修注意事项list
    async getIconList() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getMiniTipsListUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.iconList = res.data;
      }
    },
    // 获取房屋详情
    getHouseDetail(id) {
      let params = {
        spaceId: id,
      };
      this.$axios.get(`${getHouseInfoUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.loadIframe();
          this.houseDetail = res.data;
        }
      });
    },
    confirmPicker(oValue) {
      this.show = false;
      this.placeStr = oValue.label;
      this.getHouseDetail(oValue.value);
    },
    cancel() {
      this.show = false;
    },
    clickOverlay() {
      this.show = false;
    },
    toDetail(item) {
      if (item.id == 0) {
        this.$toast("敬请期待！");
        return;
      }
      this.$router.push({
        name: "decorationTipsDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px;
  background: #fafafa;
  .icon-list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: calc(100% / 4);
      margin-top: 20px;
      .img-box {
        width: 100px;
        height: 100px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
        border-radius: 50%;
        margin: 0 auto;
        position: relative;
        img {
          width: 50px;
          height: 50px;
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .name {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        margin-top: 12px;
        white-space: nowrap;
      }
    }
  }
  .topBox {
    // height: 872px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 0 35px 25px;
    box-sizing: border-box;
    .box-bottom {
      margin-top: 18px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 20px;
      }
      p {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 20px;
      }
    }
    .box-top {
      width: 100%;
      height: 98px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      .downArrow {
        transform: rotate(180deg);
      }
      img {
        width: 20px;
        height: 14px;
        margin-left: 18px;
      }
    }
    .box-center {
      width: 100%;
      height: 376px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  .titleBox {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding-top: 46px;
  }
}
</style>
